import Vue from "vue";
import App from "./App.vue";
import axios from "axios";
import VModal from 'vue-js-modal';
import Notifications from 'vue-notification'
import router from "./router";
Vue.config.productionTip = false;
Vue.prototype.$axios = axios;
Vue.prototype.$API_VERSION = 'v1/';
import 'vue-toast-notification/dist/theme-default.css'
import VueToast from 'vue-toast-notification'
import vuetify from './plugins/vuetify'
Vue.prototype.$BACK_URL = 'https://apibonus.meniqos.kz';
Vue.prototype.$API_VERSION_2 = process.env.VUE_APP_API_VERSION_2;
Vue.prototype.$API_URL = 'https://apibonus.meniqos.kz/api/';
Vue.prototype.$API_MAIN = process.env.VUE_APP_API;
Vue.config.productionTip = false;


axios.interceptors.response.use(
    response => {
      return response;
    },
    error => {
      if (error.response && error.response.status === 401) {
        window.location.href = '/login';
      }
      return Promise.reject(error);
    }
);

Vue.use(VModal);
Vue.use(Notifications)
Vue.use(VueToast)
new Vue({
  router,
  vuetify,
  render: (h) => h(App)
}).$mount("#app");