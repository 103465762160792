export const routes = [{
    path: '/login',
    name: "Login",
    component: () =>
        import ('../pages/AuthPage.vue'),
    },
    {

        path: '/',
        component: () =>
            import ('../pages/MainPage.vue'),
        name: "MainPage",
        children: [
            {
                path: '/contracts', // путь по умолчанию
                component: () => import('../pages/ContractPage.vue'),
                name: 'ContractPage'
            },
            {
                path: '/contract_view/:hash', // путь по умолчанию
                component: () => import('../pages/OwnerContract.vue'),
                name: 'OwnerContract'
            },
            {
                path: '', // путь по умолчанию
                component: () => import('../pages/DocumentPage.vue'),
                name: 'DocumentPage'
            },
            {
                path: '/profiles', // путь по умолчанию
                component: () => import('../pages/ProfilePage.vue'),
                name: 'ProfilePage'
            },
            {
                path: "/hit",
                component: () =>
                    import ("../pages/HitPage.vue"),
                name: "hit",
            },
            {
                path: "/prevention",
                component: () =>
                    import ("../pages/PreventionPage.vue"),
                name: "prevention",
            },
        ]
    },
    {
        path: '/contract/:hash',
        component: () =>
            import ('../pages/ContractView.vue'),
        name: "ContractView",
    },
    {
        path: '/privacy_page', // путь по умолчанию
        component: () => import('../pages/PrivacyPage.vue'),
        name: 'PrivacyPage'
    },
    {
        path: '/access_page', // путь по умолчанию
        component: () => import('../pages/AccessPage.vue'),
        name: 'AccessPage'
    }
]